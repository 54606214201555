@import "/src/styles/mixin";

.questions_wrap {
  display: none;
  flex-direction: column;
  position: fixed;
  width: 320px;
  height: 537px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  padding: 16px;
  background-color: var(--white-color);
  border: 1px solid var(--grey-lines-color);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  transition: all .6s;
  transition-delay: 1s;

  overflow: auto;

  .questions_head {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

    .step {
      @include font14;
      font-weight: 600;
    }

    .img {
      width: 130px;
      height: auto;
    }
  }

  .questions_content {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    height: calc(100% - 60px);
    gap: 8px;

    .title {
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      margin-bottom: 4px;
    }

    .formRadio {
      padding: 17.5px 24px;
      border: 1px solid var(--grey-lines-color);
      border-radius: var(--border-radius-small);
      cursor: pointer;
    }

    .shop {
      height: calc(100% - 50px);

      .shop_input {
        margin-bottom: 20px;
        background-color: var(--white-color);
        border: 1px solid var(--primary-color);
      }

      .shop_title {
        @include font16;
        margin-bottom: 24px;
      }

      .shop_list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        height: calc(100% - 60px);
        padding-bottom: 50px;
        // max-height: 430px;
        overflow: auto;
        // margin-bottom: 40px;

        .shop_button {
          display: flex;
          align-items: center;
          gap: 12px;
          text-align: left;

          .icon {
            width: 16px;
            height: auto;
          }
        }
      }
    }


    .wayes {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .way {
        padding: 16px 10px;
        background-color: var(--blue-background-color);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: var(--border-radius-small);
        cursor: pointer;
        font-weight: 700;

        .chevron {
          transform: rotate(-90deg);
        }
      }
    }

    .range_title {
      color: var(--grey-darker-color);
      margin-bottom: 4px;
      font-weight: 500;
      font-size: 12px;
    }

    .range {
      display: flex;
      flex-direction: row;
      gap: 4px;
      margin-bottom: 24px;
      
      .range_line {
        width: 100%;
        background-color: var(--blue-background-color);
        height: 4px;
        border-radius: 4px;

        &_active {
          background-color: var(--primary-color);
        }
      }
    }

    .grade_list {
      display: grid;
      grid-template-columns: repeat(5, minmax(auto, 1fr));
      row-gap: 16px;
      column-gap: 22px;
      margin: 20px 0;

      .grade {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: .1em;
        border: 1px solid var(--orange-color);
        border-radius: var(--border-radius-small);
        max-width: 40px;
        max-height: 40px;
        aspect-ratio: 1 / 1;
      }

      .grade_active {
        background-image: linear-gradient(224.9deg, #FFDD2D 24%, #FFB82D 97.28%);
        ;
      }
    }

    .grade_title,
    .grade_text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 4px;
    }

    .grade_title {
      margin-bottom: 20px;
    }

    .grade_button {
      width: 100%;
      padding: 10px 24px;
      background-color: var(--orange-color);
      border-radius: var(--border-radius-small);
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      margin-top: 38px;
    }

    .review_links {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 20px;

      .link {
        display: flex;
        padding: 10px 20px;
        align-items: center;
        gap: 16px;
        border: 1px solid var(--orange-color);
        border-radius: var(--border-radius-small);
      }
    }

    .bad_review {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-top: 12px;

      .textarea {
        border-radius: var(--border-radius-small);
        outline: none;
        border: 1px solid var(--grey-lines-color);
        padding: 8px 10px;
        height: 154px;
        max-height: 154px;
        resize: none;
      }
    }

    .survey_end_wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      .survey_end {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        margin: auto 0;

        .end_title {
          font-size: 19px;
          font-weight: 800;
          line-height: 24px;
          letter-spacing: .01em;
          text-align: center;
        }

        .end_text {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: .01em;
          text-align: center;
        }
      }
    }


    .submit_bad {
      width: 100%;
      padding: 10px 24px;
      background-color: var(--orange-color);
      line-height: 24px;
      border-radius: var(--border-radius-small);
      font-size: 14px;
      font-weight: 700;
      margin-top: 12px;
    }

    .subtitle {
      margin-bottom: 20px;
      font-weight: 400;
    }

    .social_list {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .social {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 10px 20px;
        border: 1px solid var(--primary-color);
        border-radius: var(--border-radius-small);

        .icon {
          width: 23px;
          height: 23px;
        }

        .vk_icon {
          background-color: #345E90;
          border-radius: 4px;

          path {
            fill: var(--white-color);
          }
        }
      }
    }

    .preview_wrap {
      display: flex;
      flex-direction: column;
      gap: 19px;

      .preview {
        display: flex;
        flex-direction: column;
        padding: 12px;
        gap: 16px;
        background-color: var(--blue-background-color);
        border-radius: var(--border-radius-small);

        &_title {
          @include font18;
        }

        &_text {
          @include font16;
        }

        .actions_wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;

          .link_elem {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 9px;

            .img {
              width: 100%;
              max-width: 62px;
              max-height: 19px;
              height: auto;
            }

            .link {
              padding: 8px 12px;
              @include contentButton;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .questions_footer {
    margin-top: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;

    .back_button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--blue-background-color);
      height: 40px;
      width: 40px;
      border-radius: var(--border-radius-small);

      svg {
        path {
          fill: var(--grey-third);
        }
      }
    }

    .button {
      @include contentButton;
      @include font14;
      font-weight: 700;
      padding: 8px 43px;
    }
  }

  .shadow {
    display: none;
  }

  .form {
    display: flex;
    flex-direction: column;

    .required {
      @include font11;
      margin: 4px 0 12px;
      color: var(--grey-color);
    }

    .tel_input {
      width: 100%;
      background-color: var(--blue-background-color);
      padding: 12px 16px;
      border-radius: var(--border-radius-small);
      transition: all 0.25s ease;

      input {
        border: none;
        padding: 0 0;
      }

      label {
        color: var(--grey-third);
      }
    }
  }

  @media screen and (max-width: 767px) {
    transition-delay: 0s;
  }

  @media screen and (max-width: 475px) {
    right: 5px;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    display: flex;
  }
}

.questions_closed {
  display: flex;
  position: relative;
  flex-direction: column;
  position: fixed;
  width: 320px;
  height: 537px;
  top: 50%;
  right: -320px;
  transform: translate(0, -50%);
  z-index: 100;
  padding: 16px;
  background-color: var(--white-color);
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--grey-lines-color);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  transition: all .6s;


  .questions_head {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

    .step {
      @include font14;
      font-weight: 600;
    }

    .img {
      width: 130px;
      height: auto;
    }
  }

  .questions_content {
    margin-top: 28px;
    height: 100%;
  }

  .shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;

    &_button {
      position: relative;
      width: 24px;
      height: 24px;

      .icon {
        path {
          fill: var(--primary-color);
        }
      }
    }
  }
}

.questions_footer {
  width: 100%;
  display: flex;
  align-items: flex-end;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    @include contentButton;
    @include font14;
    font-weight: 700;
    padding: 8px 43px;

    .icon {
      margin-left: 10px;
      transform: rotate(-180deg);
    }
  }
}